@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button2 {
  width: auto;
  background-color: transparent;
  border-radius: 8px;
  border: 2px #fafafa solid;
  color: #fafafa;
  padding: 8px 32px;
  text-decoration: none;
}

.button2:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.textContainer {
  width: 100%;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.firstParagraph {
  text-align: center;
  color: #f4f4f4;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.24;
  word-wrap: break-word;
  padding: 8px;
}

.videoContainer {
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportLarge) {
    flex-direction: row;
  }

  @media (--viewportMedium) {
    padding-left: 10vh;
    padding-right: 10vh;
  }
}

.textContainerVideo {
  width: 100%;
  margin-top: 4%;

  @media (--viewportLarge) {
    width: 40%;
  }
}

.buttonVideo {
  display: inline-block;
  padding: 8px 20px;
  font-size: 15px;
  background-color: var(--purpleTecmilenio);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;
  border-color: var(--purpleTecmilenio);
  margin-bottom: 5%;
  margin-left: 5%;

  &:hover {
    text-decoration: none;
    background-color: var(--purpleTecmilenio);
  }

  @media (--viewportLarge) {
    margin-left: 0%;
  }

  @media (--viewportMedium) {
    margin-left: 0%;
  }
}

.titleText {
  color: black;
  font-weight: 500;
  letter-spacing: 0.24px;
  word-wrap: break-word;
  margin-left: 5%;

  @media (--viewportLarge) {
    margin-left: 0%;
    font-size: 24px;
  }
}

.titleSection {
  margin-left: 5%;
  word-wrap: break-word;
  @media (--viewportLarge) {
    margin-left: 0%;
  }
}

.iframeContainer {
  width: 100%;

  @media (--viewportLarge) {
    width: 60%;
  }
}

.video {
  width: 100%;
  height: 42vh;
  border: none;
  @media (--viewportLarge) {
    width: 100%;
    height: 493px;
    border: none;
  }
}

/*  width: 828px; */

.textValidar {
  text-align: center;
  color: #f4f4f4;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.24;
  word-wrap: break-word;
}

.textValidar2 {
  text-align: center;
  color: #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16;
  word-wrap: break-word;
  margin-top: 32px;
}

.button2Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 32px;
}
