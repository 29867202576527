.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.button2 {
  width: auto;
  background-color: transparent;
  border-radius: 8px;
  border: 2px #fafafa solid;
  color: #fafafa;
  padding: 8px 32px;
  text-decoration: none;
}

.button2:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
}
